<template>
    <v-container>
        <v-container
            v-if="loading"
            fluid
            class="fill-height"
        >
            <v-row justify="center">
                <v-col
                    cols="12"
                >
                    <div class="d-flex justify-center">
                        <div
                            class="d-flex flex-column "
                        >
                            <v-progress-circular
                                :size="70"
                                :width="7"
                                color="#000000"
                                indeterminate
                            />
                            <div>Henter data...</div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <validate-email
            v-else-if="!loading && requirePrivateCustomerEmail" 
        />
    
        <v-container
            v-else-if="!loading && newCustomer"
            fluid
            style="max-width: 550px; justify-content: center; display: flex;"
            align-items="center"
        >
            <v-row
                v-if="!newCustomer"
                justify="center"
            >
                <v-col
                    cols="12"
                >
                    <div class="d-flex justify-center">
                        <div
                            class="d-flex flex-column "
                        >
                            <v-progress-circular
                                :size="70"
                                :width="7"
                                color="#0060e6"
                                indeterminate
                            />
                            <div>Logger ind...</div>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <component 
                :is="conditionsComponent"
                @acceptConditions="LogIn()" 
            />
        </v-container>
    </v-container>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import IkastConditions from './conditions/IkastConditions.vue'
import NorforsConditions from './conditions/NorforsConditions.vue'
import DefaultConditions from './conditions/DefaultConditions.vue'

export default {
    components: {
        ValidateEmail: () => import('@/views/registration/ValidateEmail.vue')
    },
    data() {
        return {
            working: true,
            newCustomer: false,
            token: '',

            requirePrivateCustomerEmail: false,

            verifying: false,
            showInputError: false,
            twoFactorCode: '',
            errorMessage: 'Forkert kode',
            loading: false,
            email: '',
            valid: false
        }
    },
    computed: {
        conditionsComponent(){
            let company = process.env.VUE_APP_TERMS_COMPANY_NAME
            switch(company){
                case 'Ikast-Brande Kommune':
                    return IkastConditions
                case 'Norfors':
                    return NorforsConditions
                default:
                    return DefaultConditions
            }
        }
    },
    created() { 
        this.loading = true
        let urlParams = new URLSearchParams(window.location.search)

        this.GetSystemSetting({type: 17}).then(result => {
            if(result.setting == null){
                this.requirePrivateCustomerEmail = process.env.VUE_APP_REQUIRE_PRIVATE_CUSTOMER_EMAIL
            }else{
                this.requirePrivateCustomerEmail = result.validateEmail
            }
        })


        if(urlParams.has('error')){
            this.$router.push({ name: 'Login' })
        } else {
            this.$criiptoPrivate.redirect.match().then(response => {
                this.token = response.id_token
                this.GetCustomerPrivateByCriiptoIdentification({jwtToken: response.id_token, email: this.email}).then(result => {
                    if(result.customer && result.customer.id && result.customer.id > 0){
                        this.LogIn()
                    } else {
                        this.newCustomer = true
                        this.loading = false
                    }
                })
            }).catch(() => {
                this.$router.push({ name: 'Login' })
            })
        }
    },    
    methods: {
        HandleLogOut(){
            this.$store.dispatch('LogOut')
            this.$router.push({ name: 'Login' })
        },
        LogIn(){
            this.LogInCriiptoPrivate({
                jwtToken: this.token
            })
                .then(loggedIn => {
                    if(loggedIn){
                        this.GetCustomerPrivateByCriiptoIdentification({jwtToken: this.token}).then(result => {
                            if(result.customer){
                                this.setSelectedPrivateCustomer(result.customer)
                            }
                            this.$router.push({ name: 'Registration' })
                        })                    
                    }
                    else {
                        this.$router.push({ name: 'Login' })
                    }                  
                })
                .finally(() => {
                    this.working = false
                })
        },
        ...mapActions({
            LogInCriiptoPrivate: 'LogInCriiptoPrivate',
            GetCustomerPrivateByCriiptoIdentification: 'GetCustomerPrivateByCriiptoIdentification',
            LogOut: 'LogOut',
            CreateValidateUserEmailCode: 'CreateValidateUserEmailCode',
            ValidateUserEmailCode: 'ValidateUserEmailCode',
            GetSystemSetting: 'GetSystemSetting'
        }),
        ...mapMutations({
            setSelectedPrivateCustomer: 'setSelectedPrivateCustomer'
        }),
    }
}
</script>

<style scoped>
.acceptConditions{
    margin: 10px;
}
.otc {
    position: relative;
    width: 320;
    margin: 0 auto;
}

.otc fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

.otc fieldset legend {
    margin: 0 auto 1em;
    color: black;
}

input[type="number"] {
    width: .90em;
    line-height: 1;
    margin: .1em;
    padding: 8px 0 4px;
    font-size: 2.65em;
    text-align: center;
    appearance: textfield;
    -webkit-appearance: textfield;
    border: 2px solid #75ad40;
    color: black;
    border-radius: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* 2 group of 3 items */
/* input[type="number"]:nth-child(n+4) {
order: 2;
}
#otcGrouping::before {
content: '';
height: 2px;
width: 24px;
margin: 0 .25em;
order: 1;
background:#04690c;
} */
#codeErrorMessage {
    text-align: center;
}

/* From: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034 */
.otc label {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
</style>